import React from "react";
import PageWrapper from "../components/PageWrapper";
import HeroMini from "../sections/pear-landing/Hero-Mini-AM";
import OptimizeAquisitionAM from "../sections/pear-landing/Optimize-Acquisition-Channels-AM";
import ExperienceUnrivaledConfidenceAM from "../sections/pear-landing/Experience-Unrivaled-Confidence-AM";
import WhatMakesPearDifferentAM from "../sections/pear-landing/What-Makes-Pear-Different-AM";
import UnlockPerformanceMarketingAM from "../sections/pear-landing/Unlock-Performance-Marketing-AM";
import StartCapturingSalesAM from "../sections/pear-landing/Start-Capturing-Sales-AM";

import Process from "../sections/pear-landing/Process";
import Features from "../sections/pear-landing/Features";
import Testimonials from "../sections/pear-landing/Testimonials";
import Newsletter from "../sections/landing2/Newsletter";
import ContactSmall from "../sections/pear-landing/ContactSmall";

import { Helmet } from "react-helmet";

const PearIndex = () => {
  return (
    <>
      <Helmet>
        <title>
          Optimizing Your Most Cost-Effective Acquistion Channels | Driving Positive Return in Retail
          Spend
        </title>
        <meta
          name="description"
          content="Pear Commerce has found best stratigies to Optimize retailers most cost-effective acquistion channels."
        />
      </Helmet>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          variant: "blue",
          button: "cta" // cta, account, null
        }}
        footerConfig={{
          style: "style3" //style1, style2
        }}
      >
        <HeroMini />
        <OptimizeAquisitionAM />

        <StartCapturingSalesAM />

        <Newsletter className="bg-default-4" />
      </PageWrapper>
    </>
  );
};
export default PearIndex;
